/* results */
	#results {
		display: table;
	    height: 100%;
	}
	#results .content {
	    display: table-cell;
	    vertical-align: middle;
	}
	#results .square {
	    text-align: center;
	    padding: 0px 0px 40px 0px;
	    max-width: 950px;
	    margin: auto;
	    float: none;
	    display: table;
	    height: auto;
	}
		#results .square h1 {
		    font-size: 50px;
		    line-height: 52px;
		    font-weight: 700;
		    width: 100%;
		    display: block;
		    position: relative;
		    background-color: #33edc8;
		    max-width: 100%;
		    margin: auto;
		    padding: 0px 0px 50px 0px;
		}
		#results .square h1:before {
			content: 'Resultat';
			display: inline-block;
			width: 100%;
			float: left;
			font-size: 18px;
			font-weight: 700;
		}
		#results .square .result-info {
			padding: 0px 30px;
		}
			#results .square .result-info p {
				line-height: 28px;			
			}
			#results .square .result-info p a {
				position: relative;
				display: inline-block;
				padding-top: 0px;
				font-weight: 700;					
			}
			#results .square .result-info p a:after {
				content: '';
				display: inline-block;
				position: absolute;
				left: 0px;
				bottom: 1px;
				width: 100%;
				height: 2px;
				background-color: #162c83;
			}
			#results .square .result-info a {
				position: relative;
				display: inline-block;
				padding-top: 40px;
				font-weight: 700;		
			}
			#results .square .result-info a:after {
				content: '';
				display: inline-block;
				position: absolute;
				left: 0px;
				bottom: -3px;
				width: 100%;
				height: 2px;
				background-color: #162c83;
			}