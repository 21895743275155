/* questions */
	.backgroundPrefix {
		background-repeat: no-repeat;
	    background-position: 50% bottom;
	    background-size: 128px 128px;
	}
	.svgPrefix {
	    position: absolute;
	    display: inline-block;
	    top: 0px;
	    bottom: 0px;
	    left: 0px;
	    right: 0px;
	    opacity: 0;
	    margin: auto;
	    width: 135px;
	    height: 135px;
	}
	.svgPrefixShops {
	    position: absolute;
	    display: inline-block;
	    opacity: 0;
	    top: 0px;
	    bottom: 0px;
	    left: 0px;
	    right: 0px;
	    margin: auto;
	    width: 70px;
	    height: 70px;
	}
	.backgroundPrefixShops {
		background-repeat: no-repeat;
	    background-position: 50% bottom;
	    background-size: 68px 68px;
	}
	#questions {
		display: table;
	    height: 100vh;
	    float: none;	
	}
	#questions .svg-sprite { display: none; }
	#questions .box {
	    display: table-cell;
	    vertical-align: middle;		
	    padding: 0px 25px;
	    max-width: 300px;
	}
	#questions .content {
		background-color: #fff;
		overflow-x: hidden; 
	}
	#questions .content form { display: block; }

		#questions header p.pager {
			padding: 40px 0px;
			display: inline-block;
			font-weight: 700;
			color: #a5a5a5;
			position: relative;
		}
		#questions header p.pager span {
			display: inline-block;
			font-weight: 700;
			color: #a5a5a5;			
		}
		#questions header p.pager:after {
			content: '';
			display: inline-block;
			height: 3px;
			background-color: #162c83;
			position: absolute;
			left: 0px;
			bottom: 0px;
			width: 75px;
		}
		#questions header p.pager-prev {
		    padding: 40px 0px;
		    display: inline-block;
		    position: relative;
		    float: right;
		    cursor: default;
			-webkit-transform: translateX(97px) translateZ(0);
			transform: translateX(97px) translateZ(0);	
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
		    -webkit-transition-delay: 1s;
		    transition-delay: 1s;
			cursor: pointer;	    
		}
		#questions header p.pager-prev:before {
		    content: '';
		    display: inline-block;
		    position: absolute;
		    top: calc(50% - 10px);
		    left: -47px;
		    background-image: url("/images/arrow.svg");
		    background-repeat: no-repeat;
		    background-position: 0 50%;
		    width: 47px;
		    height: 20px;
			transform: rotate(-180deg);
			-webkit-transform: rotate(-180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(-180deg); 
		}
		#questions header p.pager-prev.active { 
			-webkit-transform: translateX(0px) translateZ(0);
			transform: translateX(0px) translateZ(0);
		    -webkit-transition-delay: 1s;
		    transition-delay: 1s;
		}	
		#questions article { padding: 40px 0px 0px 0px; float: left; width: 100%; }
		#questions article h1 {
			font-size: 42px;
			font-weight: 700;
			line-height: 52px;
		}
		#questions article .options {
			padding: 60px 0px 80px 0px;
		}
		#questions article .options .selector {
			display: block;
			text-align: center;
		}
		#questions article .options .selector .qt-content { padding: 0px 20px; }
		#questions article .options .selector label { display: block; cursor: pointer; }
		#questions article .options .selector label input[type="radio"] { display: none; }
		#questions article#qt1-1 .options .selector .image { height: 70px; }
		#questions article#qt1-1 .options .row  { 
		    padding: 40px 0px 0px 0px;
		    float: left;
		}
		#questions article .options .selector .image {
		    display: block;
		    height: 150px;
		    position: relative;
		}

			/* svg spirte prefix */
			#questions article .options .selector .image.opt1 .icon,
			#questions article .options .selector .image.opt2 .icon,
			#questions article .options .selector .image.opt3 .icon,
			#questions article .options .selector .image.opt4 .icon,
			#questions article .options .selector .image.opt5 .icon,
			#questions article .options .selector .image.opt6 .icon,
			#questions article .options .selector .image.opt7 .icon,
			#questions article .options .selector .image.opt8 .icon,
			#questions article .options .selector .image.opt9 .icon,
			#questions article .options .selector .image.opt10 .icon,
			#questions article .options .selector .image.opt11 .icon,
			#questions article .options .selector .image.opt12 .icon,
			#questions article .options .selector .image.opt13 .icon,
			#questions article .options .selector .image.opt14 .icon,
			#questions article .options .selector .image.opt15 .icon,
			#questions article .options .selector .image.opt16 .icon,
			#questions article .options .selector .image.opt17 .icon,
			#questions article .options .selector .image.opt18 .icon,
			#questions article .options .selector .image.opt19 .icon {
				@extend .svgPrefix;
			}
			#questions article .options .selector .image.opt1-1 .icon,
			#questions article .options .selector .image.opt1-2 .icon,
			#questions article .options .selector .image.opt1-3 .icon,
			#questions article .options .selector .image.opt1-4 .icon,
			#questions article .options .selector .image.opt1-5 .icon,
			#questions article .options .selector .image.opt1-6 .icon,
			#questions article .options .selector .image.opt1-7 .icon,
			#questions article .options .selector .image.opt1-8 .icon  {
				@extend .svgPrefixShops;
			}
			#questions article .options .selector .image .icon.first { opacity: 1; }
			#questions article .options .selector:hover .image .icon.first,
			#questions article .options .selector.selected .image .icon.first {
				opacity: 0;
			}
			#questions article .options .selector:hover .image .icon.second,
			#questions article .options .selector.selected .image .icon.second {
				opacity: 1;
			}

		#questions article .options .selector p {
			padding-top: 40px;
			line-height: 18px;
		}
		#questions article#qt1-1 .options .selector p {
			padding-top: 20px;
			line-height: 18px;
		}