.w1 { width: calc(99.99% * (1/12)); float: left; }
.w2 { width: calc(99.99% * (2/12)); float: left; }
.w3 { width: calc(99.99% * (3/12)); float: left; }
.w4 { width: calc(99.99% * (4/12)); float: left; }
.w5 { width: calc(99.99% * (5/12)); float: left; }
.w6 { width: calc(99.99% * (6/12)); float: left; }
.w7 { width: calc(99.99% * (7/12)); float: left; }
.w8 { width: calc(99.99% * (8/12)); float: left; }
.w9 { width: calc(99.99% * (9/12)); float: left; }
.w10 { width: calc(99.99% * (10/12)); float: left; }
.w11 { width: calc(99.99% * (11/12)); float: left; }
.w12 { width: 100%; float: left; }

/* grid rows - 2, 3, 4 */
.w3:nth-of-type(4n+1) { clear: left; }
.w4:nth-of-type(3n+1) { clear: left; } 
.w6:nth-of-type(2n+1) { clear: left; }

/* grid parent div (must be present before starting grid columns) */
.row { width: 100%; margin: 0 auto; position: relative; z-index: 1; }
.row:after {
  content: "";
  display: table;
  clear: both;
}
/* content controls the max width of the grid/layout - max-width + padding is optional */
body {
	background-color: #33edc8;
	height: 100vh;
	overflow: hidden;
}
.content { 
	max-width: 950px;
	margin: 0 auto;
	padding: 0px 25px;
}

/* responsive grid columns */
@media all 
	and (min-width: 320px) 
	and (max-width: 767px) 
	and (-webkit-min-device-pixel-ratio: 2)
	and (orientation: landscape) {
		body { 
			height: auto; 
			padding: 20px 0px;
			overflow-y: auto; 
		}
}
@media all 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: landscape) 
	and (-webkit-min-device-pixel-ratio: 2) {
		body { 
			height: auto; 
			padding: 20px 0px;
			overflow-y: auto; 
		}
		.created-by {
		    position: static;
		    z-index: 10;
		    top: inherit;
		    transform: rotate(0deg);
		    -webkit-transform: rotate(0deg);
		    -moz-transform: rotate(0deg);
		    -ms-transform: rotate(0deg);
		    left: inherit;
		    bottom: inherit;
		    width: 100%;
		    float: left;
		    text-align: center;
		    margin-bottom: 20px;
		}
}
@media (max-device-width: 1280px) 
	and (orientation: landscape) { 
		body { 
			height: auto; 
			padding: 20px 0px;
			overflow-y: auto; 
		}
		.created-by {
		    position: static;
		    z-index: 10;
		    top: inherit;
		    transform: rotate(0deg);
		    -webkit-transform: rotate(0deg);
		    -moz-transform: rotate(0deg);
		    -ms-transform: rotate(0deg);
		    left: inherit;
		    bottom: inherit;
		    width: 100%;
		    float: left;
		    text-align: center;
		    margin: 20px 0px;
		}		
}
@media all 
	and (min-width: 768px) 
	and (max-width: 1024px) { 	
	/* results */
		#results .square h1 {
		    font-size: 40px;
		    line-height: 42px;
		    padding: 0px 0px 40px 0px;
		}	
	/* dashboard */
		#dashboard .square {
		    text-align: center;
		    padding: 20px 0px 20px 0px;
		    border: 25px solid #fff;
		    width: 450px;
		    margin: auto;
		    float: none;
		    display: table;
		    height: 450px;
		}
		#dashboard .square h1 {
		    font-size: 60px;
		    line-height: 62px;
		    padding: 20px 0px;
		}
		#dashboard .square h2 {
			font-size: 17px;
			line-height: 20px;
		}
		#dashboard .button-wrap {
		    width: 250px;
		    margin-top: 30px;
		}
	.created-by {
	    position: fixed;
	    z-index: 10;
	    top: inherit;
	    transform: rotate(0deg);
	    -webkit-transform: rotate(0deg);
	    -moz-transform: rotate(0deg);
	    -ms-transform: rotate(0deg);
	    left: 0px;
	    bottom: 20px;
	    width: 100%;
	    text-align: center;
	}
}
@media all and (max-width: 767px) { 
		.content { max-width: 100%; width: 100%; padding: 0px 20px; }
		.created-by {
		    position: static;
		    display: block;
		    float: left;
		    width: 100%;
		    margin-bottom: 20px;
		    text-align: center;
		    transform: rotate(0deg);
		    -webkit-transform: rotate(0deg);
		    -moz-transform: rotate(0deg);
		    -ms-transform: rotate(0deg);
		}
		.created-by p {
			font-size: 13px;
			line-height: 40px;
			display: inline-block;
		}
		.created-by p a { font-size: 13px; }
	/* dashboard */
		#dashboard .square {
		    text-align: center;
		    padding: 20px 0px 20px 0px;
		    border: 10px solid #fff;
		    width: 250px;
		    margin: auto;
		    float: none;
		    display: table;
		    height: 280px;
		}
		#dashboard .square h1 {
		    font-size: 30px;
		    line-height: 32px;
		    padding: 20px 0px;
		}
		#dashboard .square h2 {
			font-size: 13px;
			line-height: 17px;
		}
		#dashboard .button-wrap {
		    width: 250px;
		    margin-top: 30px;
		}
	/* results */
		#results .square { padding: 0px 0px; }
		#results .square h1 {
		    font-size: 17px;
		    line-height: 23px;
		    padding: 0px 0px 20px 0px;
		}
		#results .square h1:before {
		    font-size: 15px;
		    padding: 0px 0px 20px 0px;
		}
		#results .square .result-info {
		    padding: 20px 20px;
		}
		#results .square .result-info p {
		    font-size: 13px;
		    line-height: 17px;			
		}
		#results .square .result-info p a {
		    font-size: 13px;
		    line-height: 17px;			
		}
		#results .button-wrap {
			width: 250px;
			margin-top: 20px;
		}
		#results .square .result-info a {
		    font-size: 13px;
		    padding-top: 20px;
		}
	/* questions */
		#questions .box { padding: 20px 20px; }
		#questions header p.pager {
		    padding: 20px 0px 25px 0px;
		    font-size: 15px;
		}
		#questions header p.pager span { font-size: 15px; }
		#questions header p.pager:after { width: 30px; }
		#questions header p.pager-prev {
		    padding: 20px 0px 25px 0px;
		    font-size: 15px;
			-webkit-transform: translateX(150px) translateZ(0);
			transform: translateX(150px) translateZ(0);
		}
		#questions header p.pager-prev span { font-size: 15px; }
		#questions header p.pager-prev::before { width: 30px; left: -30px; }
			#questions article { padding: 20px 0px 0px 0px; }
			#questions article h1 { font-size: 31px; line-height: 35px; }
			#questions article .options .selector .qt-content { padding: 0px 10px; }
			#questions article .options .selector p {
			    font-size: 12px;
			    line-height: 15px;
			}
	/* svg spirte prefix */
		#questions article .options .selector .image.opt1 .icon,
		#questions article .options .selector .image.opt2 .icon,
		#questions article .options .selector .image.opt3 .icon,
		#questions article .options .selector .image.opt4 .icon,
		#questions article .options .selector .image.opt5 .icon,
		#questions article .options .selector .image.opt6 .icon,
		#questions article .options .selector .image.opt7 .icon,
		#questions article .options .selector .image.opt8 .icon,
		#questions article .options .selector .image.opt9 .icon,
		#questions article .options .selector .image.opt10 .icon,
		#questions article .options .selector .image.opt11 .icon,
		#questions article .options .selector .image.opt12 .icon,
		#questions article .options .selector .image.opt13 .icon,
		#questions article .options .selector .image.opt14 .icon,
		#questions article .options .selector .image.opt15 .icon,
		#questions article .options .selector .image.opt16 .icon,
		#questions article .options .selector .image.opt17 .icon,
		#questions article .options .selector .image.opt18 .icon,
		#questions article .options .selector .image.opt19 .icon {
			width: 100px;
			height: 100px;
		}
}
@media all and (max-width: 480px) { 
	/* all columns goes to one */
		.w1, .w2, .w3, .w4, .w5, .w6,
		.w7, .w8, .w9, .w10, .w11, .w12 {
			width: 100%;
		}
	/* questions */
		#questions .box { padding: 20px 20px; }
		#questions header p.pager {
		    padding: 20px 0px 25px 0px;
		    font-size: 15px;
		}
		#questions header p.pager span { font-size: 15px; }
		#questions header p.pager:after { width: 30px; }
			#questions article { padding: 20px 0px 0px 0px; }
			#questions article h1 { font-size: 21px; line-height: 25px; }
			#questions article .selector.w4,
			#questions article .selector.w6 { width: 100%; }
			#questions article .selector.w4:nth-of-type(1n) { clear: left; } 
			#questions article .selector.w6:nth-of-type(1n) { clear: left; }
			#questions article .selector { margin-bottom: 20px; }
			#questions article .selector:last-of-type { margin-bottom: 0px; }
			#questions article .options {
			    padding: 40px 0px 40px 0px;
			}	
				#questions article .options .selector label { display: table; }
				#questions article#qt1-1 .options .selector .image { height: 50px; }
				#questions article .options .selector label .w12.image {
					width: 50px;
					height: 50px;
					float: none;
					display: table-cell;
					vertical-align: middle;
				}
				#questions article .options .selector label .w12.qt-content {
					width: calc(100% - 50px); 
					float: none;
					display: table-cell;
					vertical-align: middle;
					text-align: left;
				}
				#questions article .options.shops .selector.w3 {
					width: calc(99.99% * (6/12));
				}
				#questions article .options.shops .selector.w3 .image {
					height: 50px;
					width: 40px;
					display: block;
					float: left;
					margin: 0px;
				}
				#questions article .options.shops .selector.w3 .qt-content {
				    width: 100%;
				    float: left;
				    display: block;
				    text-align: left;
				    padding: 0px 0px;
				}
				#questions article#qt1-1 .options .selector p { padding-top: 0px; }
				#questions article .options .selector p {
				    font-size: 12px;
				    padding-top: 0px;
				    line-height: 15px;
				}
			/* svg spirte prefix */
				#questions article .options .selector .image.opt1-1 .icon,
				#questions article .options .selector .image.opt1-2 .icon,
				#questions article .options .selector .image.opt1-3 .icon,
				#questions article .options .selector .image.opt1-4 .icon,
				#questions article .options .selector .image.opt1-5 .icon,
				#questions article .options .selector .image.opt1-6 .icon,
				#questions article .options .selector .image.opt1-7 .icon,
				#questions article .options .selector .image.opt1-8 .icon, 
				#questions article .options .selector .image.opt1 .icon,
				#questions article .options .selector .image.opt2 .icon,
				#questions article .options .selector .image.opt3 .icon,
				#questions article .options .selector .image.opt4 .icon,
				#questions article .options .selector .image.opt5 .icon,
				#questions article .options .selector .image.opt6 .icon,
				#questions article .options .selector .image.opt7 .icon,
				#questions article .options .selector .image.opt8 .icon,
				#questions article .options .selector .image.opt9 .icon,
				#questions article .options .selector .image.opt10 .icon,
				#questions article .options .selector .image.opt11 .icon,
				#questions article .options .selector .image.opt12 .icon,
				#questions article .options .selector .image.opt13 .icon,
				#questions article .options .selector .image.opt14 .icon,
				#questions article .options .selector .image.opt15 .icon,
				#questions article .options .selector .image.opt16 .icon,
				#questions article .options .selector .image.opt17 .icon,
				#questions article .options .selector .image.opt18 .icon,
				#questions article .options .selector .image.opt19 .icon {
				    width: 40px;
				    height: 40px;
				}
				#questions article#qt1-1 .options .row { padding: 0px 0px; }
}
@media all and (max-width: 320px) { 
	/* questions */
		#questions article .options {
		    padding: 20px 0px 20px 0px;
		}
		#questions article h1 {
		    font-size: 17px;
		    line-height: 21px;
		}
		.questions .created-by { bottom: 10px; }
}