*, *:after, *:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
* {
	border: 0;
	padding: 0;
	margin: 0;
	list-style-type: none;
	text-decoration: none;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	
	color: #162c83;
	font-size: 17px;
}
html, body {
	height: 100%;
}

/* change background & text color on mouse select */
::selection { background: #162c83; color: #ffffff; }
::-moz-selection { background: #162c83; color: #ffffff; }
::-webkit-selection { background: #162c83; color: #ffffff; }

/* make images responsive, so they scale properly */
img { max-width: 100%; height: auto; }
/* control tables fixed width system */
table {
  table-layout: fixed;
  border-collapse: collapse;
}

/* remove scroll and resize from textarea */
textarea { overflow: auto; resize: none; }

/* iframes */
/* make iframes nice fluent & scale properly */
.video {
	padding-right: 0px;
	padding-top: 0px;
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 51%;
}
.video iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

/* animations */
/* basic transition effect */
.transition {
	-webkit-transition: all .4s;
	-moz-transition: all .4s;
	-o-transition: all .4s;
	transition: all .4s;
}
.transition-ease {
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}
/* animate the element vertically - change the translateY(40px) */
.animate-vertically {
	-webkit-transform: translateY(40px) translateZ(0);
	transform: translateY(40px) translateZ(0);
}
/* animate the element horizontally - change the translateY(40px) */
.animate-horizontally {
	-webkit-transform: translateX(40px) translateZ(0);
	transform: translateX(40px) translateZ(0);
}
.rotate {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);  
}

/* parallax */
.parallax {
	height: 100vh;
	width: 100%;
    background-image: url('');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
}

/* bx slider */
	.question-slider { float: left; height: 100%; }
	.bx-controls { display: none; }
	.bx-viewport { height: auto !important; }

/* buttons */
.button {
	display: block;
    padding: 23px 23px;
    overflow: hidden;
}
.button span {
	font-weight: 700;
	position: relative;
	display: inline-block;
	-webkit-transform: translateX(0px) translateZ(0);
	transform: translateX(0px) translateZ(0);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.button span:after {
	content: '';
	display: inline-block;
	position: absolute;
	top: calc(0px + .5px);
	left: 0px;
	background-image: url("/images/arrow.svg");
	background-repeat: no-repeat;
	width: 47px;
	height: 20px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
/* dashboard page */
	#dashboard  .button span:after {
		-webkit-transform: translateX(165px) translateZ(0);
		transform: translateX(165px) translateZ(0);
	}
	#dashboard .button-wrap:hover span:after {
		-webkit-transform: translateX(130px) translateZ(0);
		transform: translateX(130px) translateZ(0);
	}
	#dashboard .button-wrap:hover .button span {
		-webkit-transform: translateX(-55px) translateZ(0);
		transform: translateX(-55px) translateZ(0);
	}
	#dashboard .button-wrap {
	    display: block;
	    position: relative;
	    width: 220px;
	    margin: auto;
	    text-align: center;
	    background-color: #fff;
	    margin-top: 70px;
	    cursor: pointer;
	}

/* results page */
	#results  .button span:after {
		-webkit-transform: translateX(225px) translateZ(0);
		transform: translateX(225px) translateZ(0);
	}
	#results .button-wrap:hover span:after {
		-webkit-transform: translateX(160px) translateZ(0);
		transform: translateX(160px) translateZ(0);
	}
	#results .button-wrap:hover .button span {
		-webkit-transform: translateX(-35px) translateZ(0);
		transform: translateX(-35px) translateZ(0);
	}
	#results .button-wrap {
	    display: block;
	    position: relative;
	    width: 260px;
	    margin: auto;
	    text-align: center;
	    background-color: #fff;
	    margin-top: 10px;
	    cursor: pointer;
	}

.button-wrap::before,
.button-wrap::after,
.button::before,
.button::after {
	content: '';
	position: absolute;
	background: #162c83;
	-webkit-transition: -webkit-transform 0.1s;
	transition: transform 0.1s;
	-webkit-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
	transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
}

/* left and right line */
.button-wrap::before,
.button-wrap::after {
	top: 0;
	width: 2px;
	height: 100%;
	-webkit-transform: scale3d(1, 0, 1);
	transform: scale3d(1, 0, 1);
}

/* left line */
.button-wrap::before {
	left: 0;
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
}

/* right line */
.button-wrap::after {
	right: 0;
	-webkit-transform-origin: 50% 0%;
	transform-origin: 50% 0%;
}

/* top and bottom line */
.button::before,
.button::after {
	left: 0;
	width: 100%;
	height: 2px;
	-webkit-transform: scale3d(0, 1, 1);
	transform: scale3d(0, 1, 1);
}

/* top line */
.button::before {
	top: 0;
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%;
}

/* bottom line */
.button::after {
	bottom: 0;
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
}

/* bottom line */
.button-wrap .button::after {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.button-wrap:hover .button::after {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

/* left line */
.button-wrap::before {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.button-wrap:hover::before {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

/* top line */
.button-wrap .button::before {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.button-wrap:hover .button::before {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

/* right line */
.button-wrap:hover::after {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.button-wrap:hover::before,
.button-wrap:hover::after,
.button-wrap:hover .button::before,
.button-wrap:hover .button::after {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}

