/* created by sticker */
	.created-by {
		position: fixed;
		z-index: 10;
		top: calc(50% - 20px);
		right: -20px;
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);  
	}
 		p.underline a {
			font-weight: 700;
			text-decoration: underline;
		}

/* dashboard */
	#dashboard {
		display: table;
	    height: 100%;
	}
	#dashboard .content {
	    display: table-cell;
	    vertical-align: middle;
	}
	#dashboard .square {
	    text-align: center;
	    padding: 50px 0px 50px 0px;
	    border: 25px solid #fff;
	    max-width: 490px;
	    margin: auto;
	    float: none;
	    display: table;
	    height: 480px;
	}
		#dashboard .square h1 {
		    font-size: 70px;
		    line-height: 72px;
		    font-weight: 700;
		    width: 100%;
		    position: absolute;
		    background-color: #33edc8;
		    z-index: 2;
		    left: 0px;
		    display: block;
		    max-width: 100%;
		    right: 0px;
		    margin: auto;
		    padding: 25px 0px;
		}
		#dashboard .square h2 {
		    display: table-cell;
		    vertical-align: bottom;
		    padding: 0px 20px;
		    line-height: 28px;
		}